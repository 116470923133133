*, *::before, *::after {
    box-sizing: border-box;
}

i , svg{
    box-sizing: unset !important;
}

.custom-items-box>div {
    width: 100% !important;
}
.accessories1 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
}

.accessories2 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    gap: 0 !important;
    grid-template-columns: 1fr;
    align-items: center;
}

.accessories2>div:nth-child(1) {
    align-self: center;
}

.accessories2>div:nth-child(2) {
    align-self: center;
}

.accessories3 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    gap: 0 !important;
    grid-template-columns: 1fr;
}

.accessories4 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    gap: 0 !important;
    grid-template-columns: 1fr;
}

.accessories4>div {
    align-self: center;
    justify-self: center;
}

.dress1 img {
    /* height: 360px; */
    height: 520px;
    min-width: 180px;
    max-width: 180px;
}

.GroupList1 img {
    max-height: 560px !important;
    min-height: 560px !important;
    max-width: 80% !important;
    padding: 6px;
}

.subItemList1 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
}

.subItemList2 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
}

.subItemList2>div:nth-child(1) {
    align-self: start;
}

.subItemList2>div:nth-child(2) {
    align-self: end;
}

.subItemList3 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
}

.subItemList4 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
}

.subItemList4>div {
    align-self: center;
    justify-self: center;
}

.subItemList5 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
}

.subItemList6 {
    display: -webkit-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.subItemList6>div {
    align-self: center;
    justify-self: center;
}

.subItemList6 img {
    max-height: 80px !important;
    min-height: 80px !important;
}

.GroupList-wrapper1:has(.subItemList6)~.footwearList img {
    min-height: 80px !important;
    max-height: 80px !important;
}

.GroupList-wrapper1:has(.subItemList5)~.footwearList img {
    min-height: 80px !important;
    max-height: 80px !important;
}

.subItemList5 img {
    min-height: 88px !important;
    max-height: 88px !important;
}

.subItemList1 img {
    min-height: 250px !important;
    max-height: 250px !important;
}

.accessoriesFootwearDiv.d-none {
    display: none !important;
}

.custom-items-box.custom-list-items {
    grid-template-columns: repeat(1, 1fr);
}

.GroupList-wrapper0 {
    display: none !important;
}

/* new bootom box css */
.accessories2>div:not(:last-child),
.accessories3>div:not(:last-child),
.accessories4>div:not(:last-child) {
    border-bottom: 1px solid #151515;
}

.dress2>div:nth-child(1) {
    max-height: 220px;
    border-bottom: 1px solid #151515;
}

.dress2>div:nth-child(1) img {
    min-width: 180px;
    max-width: 180px;
    height: 190px;
    min-height: 190px;
}

.dress2>div:nth-child(2) img {
    min-width: 180px;
    max-width: 180px;
    height: 310px;
    min-height: 310px;
}

.accessories2 img {
    min-width: 180px;
    max-width: 180px;
    min-height: 200px;
    height: 200px;
}

.accessories1 img {
    height: 250px;
    min-width: 180px;
    max-width: 180px;
}

.accessories3 img {
    max-width: 180px;
    min-width: 180px;
    height: 160px;
    min-height: 160px;
}

.accessories4 img {
    min-width: 180px;
    max-width: 180px;
    height: 120px;
    min-height: 120px;
}


/* new grouplist css */
.GroupList2,
.GroupList3,
.GroupList1,
.dress1,
.dress2 {
    border-right: 1px solid #151515;
}

.custom-items-box {
    column-gap: 0 !important;
}

.GroupList2>div:not(:last-child),
.outerwearList1 {
    border-bottom: 1px solid #151515;
}

.GroupList2>div:first-child {
    max-height: 210px;
}

.GroupList2>div:last-child {
    max-height: 384px;
}

.GroupList2>div:first-child img {
    max-height: 165px !important;
    min-height: 165px !important;
}

.GroupList2>div:last-child img {
    min-height: 265px !important;
    max-height: 265px !important;
}

.subItemList2>div:nth-child(1) {
    max-height: 230px;
    border-bottom: 1px solid #151515;
    align-self: center;
    height: 230px;
}

.footwearList1>div {
    max-height: 180px;
    height: 180px;
}

.subItemList2>div:nth-child(2) {
    max-height: 180px;
    align-self: center;
    height: 180px;
}

.footwearList1 {
    height: 180px;
    max-height: 180px;
    border-top: 1px solid #151515;
}

.footwearList1>div img {
    min-height: 135px !important;
    min-width: 135px !important;
    max-width: 170px !important;
    padding: 2px 0;
}

.subItemList2>div:nth-child(1) img {
    min-height: 186px !important;
    min-width: 186px !important;
    max-width: 186px !important;
}

.subItemList2>div:nth-child(2) img {
    min-height: 160px !important;
    min-width: 160px !important;
    max-width: 160px !important;
}

.subItemList1 img {
    min-width: 150px !important;
    max-width: 150px !important;
}

.GroupList-wrapper1:has(.subItemList3)~.footwearList1,
.GroupList-wrapper1:has(.subItemList3)~.footwearList1>div {
    height: 147px;
    max-height: 147px;
}

.subItemList3>div:not(:last-child) {
    border-bottom: 1px solid #151515;
}

.subItemList3>div {
    max-height: 146px;
    height: 146px;
}

.subItemList3>div img {
    max-height: 120px !important;
    min-height: 120px !important;
    max-width: 180px !important;
    min-width: 180px !important;
}

.GroupList-wrapper0~.footwearList1 {
    border-top: 0;
}

.GroupList0 {
    display: none !important;
}

.custom-items-box.custom-list-items .GroupList2,
.custom-items-box.custom-list-items .GroupList1 {
    border-right: 0;
}

.custom-items-box:has(.GroupList0) {
    grid-template-columns: repeat(1, 1fr);
}

.outerwearList1~.GroupList-wrapper1 .subItemList1 img {
    min-height: 180px !important;
    max-height: 180px !important;
}

.outerwearList1>div {
    max-height: 180px;
    min-height: 180px;
}

.outerwearList1>div img {
    max-width: 200px !important;
    min-width: 200px !important;
    min-height: 155px !important;
}

.GroupList-wrapper0~.footwearList1>div {
    max-height: 290px;
    height: 290px;
}

.outerwearList1.outWearWrapper>div img {
    min-height: 250px !important;
}

.outerwearList1.outWearWrapper>div {
    max-height: 290px;
    min-height: 290px;
}

.accessoriesFootwearDiv:has(.outWearWrapper) {
    justify-content: flex-start !important;
}

.outerwearList1.outWearOne {
    border: 0;
}

.accessoriesFootwearDiv:has(.outWearWrapper.outWearOne) {
    justify-content: center !important;
}

.outerwearList1.outWearOne~.GroupList-wrapper1 {
    border-top: 1px solid #151515;
}

.GroupList-wrapper1.border-0 {
    border: 0 !important;
}

.outerwearList1.outWearOne>div {
    max-height: 290px;
    min-height: 290px;
}

.accessoriesFootwearDiv:has(.outerwearList0.outWearWrapper) {
    justify-content: center !important;
}

.accessoriesFootwearDiv i,
.GroupList2 i {
    top: 6px !important;
    right: 15px !important;
}

.GroupList1 i {
    top: 20px !important;
    right: 10px !important;
}

/* Responsive css */
@media (max-width:1024px) {
    .accessoriesFootwearDiv i {
        top: 15px !important;
        right: 20px !important;
    }

    .GroupList2 i {
        top: 16px !important;
        right: 20px !important;
    }

    .dress1 img {
        height: 340px;
    }

    .GroupList-wrapper1:has(.subItemList6)~.footwearList img {
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .GroupList2 img {
        min-height: 140px !important;
        max-height: 140px !important;
    }

    .GroupList1 img {
        min-height: 280px !important;
        max-height: 280px !important;
        min-width: 140px !important;
    }

    .subItemList5 img {
        min-height: 70px !important;
        max-height: 70px !important;
    }

    .GroupList-wrapper1:has(.subItemList5)~.footwearList img {
        min-height: 60px !important;
        max-height: 60px !important;
    }

    .subItemList4 img {
        min-height: 110px !important;
        max-height: 110px !important;
    }

    .GroupList-wrapper1:has(.subItemList4)~.footwearList img,
    .GroupList-wrapper1:has(.subItemList3)~.footwearList img,
    .GroupList-wrapper1:has(.subItemList2)~.footwearList img {
        min-height: 70px !important;
        max-height: 70px !important;
    }

    .subItemList2 img,
    .subItemList3 img {
        min-height: 100px !important;
        max-height: 100px !important;
    }

    .subItemList3>div img {
        max-height: 90px !important;
        min-height: 90px !important;
        max-width: 120px !important;
        min-width: 120px !important;
    }

    .GroupList-wrapper1:has(.subItemList3)~.footwearList1,
    .GroupList-wrapper1:has(.subItemList3)~.footwearList1>div {
        height: 100px;
        max-height: 100px;
    }

    .subItemList3>div {
        max-height: 99px;
        height: 99px;
    }

    .footwearList1>div img {
        min-height: 90px !important;
        min-width: 100px !important;
        max-width: 90px !important;
    }

    .GroupList2>div:first-child img {
        max-height: 130px !important;
        min-height: 130px !important;
    }

    .GroupList2>div:last-child img {
        min-height: 230px !important;
        max-height: 230px !important;
    }

    .GroupList2>div:first-child {
        max-height: 159px;
    }

    .GroupList2>div:last-child {
        max-height: 240px;
    }

    .subItemList2>div:nth-child(1),
    .subItemList2>div:nth-child(2) {
        max-height: 149px;
        height: 149px;
    }

    .subItemList2>div:nth-child(1) img,
    .subItemList2>div:nth-child(2) img {
        min-height: 100px !important;
        min-width: 120px !important;
        max-width: 120px !important;
    }

    .footwearList1>div,
    .footwearList1 {
        max-height: 100px;
        height: 100px;
    }

    .subItemList1 img {
        min-width: 120px !important;
        max-width: 120px !important;
    }

    .GroupList1>div {
        max-height: 400px;
    }

    /* new inspired box css */
    .dress2>div:nth-child(1) {
        max-height: 180px;
    }

    .dress2>div:nth-child(1) img {
        min-width: unset;
        max-width: unset;
        height: 160px;
        min-height: 160px;
        padding: 0 8px;
        width: 100%;
    }

    .dress2>div:nth-child(2) img {
        min-width: unset;
        max-width: unset;
        height: 200px;
        min-height: 200px;
        width: 100%;
        padding: 0 8px;
    }

    .accessories1 img {
        min-width: unset;
        max-width: unset;
        width: 100%;
        padding: 0 8px;
    }

    .accessories2 img {
        min-width: -webkit-fill-available;
        min-height: 160px;
        height: 160px;
        width: 100%;
        padding: 0 8px;
        max-width: 100%;
    }

    .accessories3 img {
        max-width: unset;
        min-width: unset;
        height: 120px;
        min-height: 120px;
        padding: 0 8px;
    }

    .accessories4 img {
        max-width: unset;
        min-width: unset;
        height: 90px;
        min-height: 90px;
        padding: 2px 4px;
    }

    .outerwearList1>div img {
        max-width: unset !important;
        min-width: unset !important;
        min-height: 130px !important;
        padding: 0 4px;
    }

    .outerwearList1>div {
        max-height: 150px;
        min-height: 150px;
    }

    .outerwearList1~.GroupList-wrapper1 .subItemList1 img {
        min-height: 120px !important;
        max-height: 120px !important;
    }

    .outerwearList1.outWearOne>div {
        max-height: 210px;
        min-height: 210px;
    }

    .GroupList-wrapper0~.footwearList1,
    .GroupList-wrapper0~.footwearList1>div {
        max-height: 106px;
        height: 106px;
    }

    .accessoriesFootwearDiv:has(.outWearWrapper) {
        justify-content: center !important;
    }
}

@media (max-width:450px) {
    .accessoriesFootwearDiv i {
        top: 15px !important;
        right: 20px !important;
    }

    .GroupList2 i {
        top: 16px !important;
        right: 20px !important;
    }

    .dress1 img {
        height: 340px;
    }

    .GroupList-wrapper1:has(.subItemList6)~.footwearList img {
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .GroupList2 img {
        min-height: 140px !important;
        max-height: 140px !important;
    }

    .GroupList1 img {
        min-height: 280px !important;
        max-height: 280px !important;
        min-width: 140px !important;
    }

    .subItemList5 img {
        min-height: 70px !important;
        max-height: 70px !important;
    }

    .GroupList-wrapper1:has(.subItemList5)~.footwearList img {
        min-height: 60px !important;
        max-height: 60px !important;
    }

    .subItemList4 img {
        min-height: 110px !important;
        max-height: 110px !important;
    }

    .GroupList-wrapper1:has(.subItemList4)~.footwearList img,
    .GroupList-wrapper1:has(.subItemList3)~.footwearList img,
    .GroupList-wrapper1:has(.subItemList2)~.footwearList img {
        min-height: 70px !important;
        max-height: 70px !important;
    }

    .subItemList2 img,
    .subItemList3 img {
        min-height: 100px !important;
        max-height: 100px !important;
    }

    .subItemList3>div img {
        max-height: 90px !important;
        min-height: 90px !important;
        max-width: 120px !important;
        min-width: 120px !important;
    }

    .GroupList-wrapper1:has(.subItemList3)~.footwearList1,
    .GroupList-wrapper1:has(.subItemList3)~.footwearList1>div {
        height: 100px;
        max-height: 100px;
    }

    .subItemList3>div {
        max-height: 99px;
        height: 99px;
    }

    .footwearList1>div img {
        min-height: 90px !important;
        min-width: 100px !important;
        max-width: 90px !important;
    }

    .GroupList2>div:first-child img {
        max-height: 130px !important;
        min-height: 130px !important;
    }

    .GroupList2>div:last-child img {
        min-height: 230px !important;
        max-height: 230px !important;
    }

    .GroupList2>div:first-child {
        max-height: 159px;
    }

    .GroupList2>div:last-child {
        max-height: 240px;
    }

    .subItemList2>div:nth-child(1),
    .subItemList2>div:nth-child(2) {
        max-height: 149px;
        height: 149px;
    }

    .subItemList2>div:nth-child(1) img,
    .subItemList2>div:nth-child(2) img {
        min-height: 100px !important;
        min-width: 120px !important;
        max-width: 120px !important;
    }

    .footwearList1>div,
    .footwearList1 {
        max-height: 100px;
        height: 100px;
    }

    .subItemList1 img {
        min-width: 120px !important;
        max-width: 120px !important;
    }

    .GroupList1>div {
        max-height: 400px;
    }

    /* new inspired box css */
    .dress2>div:nth-child(1) {
        max-height: 180px;
    }

    .dress2>div:nth-child(1) img {
        min-width: unset;
        max-width: unset;
        height: 160px;
        min-height: 160px;
        padding: 0 8px;
        width: 100%;
    }

    .dress2>div:nth-child(2) img {
        min-width: unset;
        max-width: unset;
        height: 200px;
        min-height: 200px;
        width: 100%;
        padding: 0 8px;
    }

    .accessories1 img {
        min-width: unset;
        max-width: unset;
        width: 100%;
        padding: 0 8px;
    }

    .accessories2 img {
        min-width: -webkit-fill-available;
        min-height: 160px;
        height: 160px;
        width: 100%;
        padding: 0 8px;
        max-width: 100%;
    }

    .accessories3 img {
        max-width: unset;
        min-width: unset;
        height: 120px;
        min-height: 120px;
        padding: 0 8px;
    }

    .accessories4 img {
        max-width: unset;
        min-width: unset;
        height: 90px;
        min-height: 90px;
        padding: 2px 4px;
    }

    .outerwearList1>div img {
        max-width: unset !important;
        min-width: unset !important;
        min-height: 130px !important;
        padding: 0 4px;
    }

    .outerwearList1>div {
        max-height: 150px;
        min-height: 150px;
    }

    .outerwearList1~.GroupList-wrapper1 .subItemList1 img {
        min-height: 120px !important;
        max-height: 120px !important;
    }

    .outerwearList1.outWearOne>div {
        max-height: 210px;
        min-height: 210px;
    }

    .GroupList-wrapper0~.footwearList1,
    .GroupList-wrapper0~.footwearList1>div {
        max-height: 106px;
        height: 106px;
    }

    .accessoriesFootwearDiv:has(.outWearWrapper) {
        justify-content: center !important;
    }
}