.MuiPopover-paper{
    min-width: 200px !important;
    max-width: 350px !important;
}

.MuiMenuItem-root{
    padding-left: 12px !important;
}

.MuiTypography-root{
    padding-left: 0 !important;
}