/* new css */
.common-div-wrapper-box .groupClothList-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid #000;
}

.common-div-wrapper-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-template-rows: 1fr;
    column-gap: 0;
}

.common-div-wrapper-box .groupClothList-2 img , .common-div-wrapper-box .groupClothList-1 img {
    max-width: 100%;
    object-fit: contain;
    height: 100px;
}

.common-div-wrapper-box .Right-block-div img {
    max-width: 100%;
    height: 100px;
    object-fit: contain;
}

.common-div-wrapper-box .Right-block-div {
    display: flex;
    row-gap: 0px;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.common-div-wrapper-box .footwearList-1 {
    border-top: 1px solid #151515;
}
.groupClothList-2 > div:first-child {
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupClothList-2 > div:last-child {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupClothList-2 > div:not(:last-child) {
    border-bottom: 1px solid #000;
}

.groupClothList-2 > div:last-child img {
    height: 150px;
}

.groupClothList-2 > div:first-child img {
    height: 90px;
}
.groupClothList-1 {
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupClothList-1 .img-box {
    max-height: 280px;
    height: 280px;
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupClothList-1 .img-box img {
    height: 250px;
}
.footwearList-1 > div {
    height: 92px;
    max-height: 92px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Right-block-div .footwearList-1 > div img {
    height: 88px;
}
.accessoriesList-2 > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px !important;
    max-height: 90px !important;
}
.accessoriesList-2 > div:not(:last-child) {
    border-bottom: 1px solid #000;
}

.Right-block-div .accessoriesList-2 > div img {
    height: 80px !important;
}
.outerwearList-0 ,.accessoriesList-0 , .footwearList-0 , .groupClothList-0 {
    display: none;
}

.Right-block-div.d-none {
    display: none !important;
}
.common-div-wrapper-box.custom-block .groupClothList-1 , .common-div-wrapper-box.custom-block .groupClothList-2 , .outerwearList-0 ~ .accessoriesList-0 ~ .footwearList-1 {
    border: 0;
}
.img-box.accessoriesBlock , .img-box.outerwearBlock , .img-box.accessoriesBlock , .img-box.footwearBlock {
    height: 250px;
    max-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.Right-block-div .img-box.accessoriesBlock img , .Right-block-div .img-box.outerwearBlock img , .Right-block-div .img-box.footwearBlock img{
    height: 250px;
}
.outerwearList-1 ~ .accessoriesList-1 {
    border-top: 1px solid #000;
}

.outerwearList-1 > div {
    height: 90px;
    max-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Right-block-div .outerwearList-1 > div img {
    height: 80px;
}

.outerwearList-1 ~ .accessoriesList-1 > div img {
    height: 80px;
}

.outerwearList-1 ~ .accessoriesList-1 > div {
    height: 90px;
    max-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Right-block-div.listing-div .accessoriesList-1>div {
    height: 140px;
    max-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Right-block-div.listing-div .footwearList-1>div , .accessoriesList-1  {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Right-block-div.listing-div .outerwearList-1 ~ .accessoriesList-1 > div {
    height: 90px;
    max-height: 90px;
}

.Right-block-div.outwear-accessory-block .outerwearList-1 > div {
    height: 140px;
    max-height: 140px;
}

.Right-block-div.outwear-accessory-block .outerwearList-1 > div img {
    height: 120px;
}
.Right-block-div.outwear-accessory-block.listing-div .outerwearList-1 > div {
    height: 90px;
    max-height: 90px;
}

.Right-block-div.outwear-accessory-block.listing-div .outerwearList-1 > div img {
    height: 80px;
}

.Right-block-div.outwear-footwear-block .outerwearList-1 > div {
    height: 140px;
    max-height: 140px;
}

.Right-block-div.outwear-footwear-block .outerwearList-1 > div img {
    height: 120px;
}