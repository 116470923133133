* {
  margin: 0;
  padding: 0;
}

:root {
--primaryHeaderFont: "Mulish";
--primaryRegularFont: "Roboto";
--primaryRegularItalicFont: "RobotoItalic";
--secondaryHeaderFont: "Lato";
--secondaryRegularFont: "Karla";
}

#root {
  overflow: hidden;
}

#modal-root {
  position: relative;
  z-index: 999;
}

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), url("./assets/fonts/Mulish.ttf") format("truetype");
}

@font-face {
  font-family: "MulishItalic";
  src: local("MulishItalic"), url("./assets/fonts/Mulish-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "MulishBold";
  src: local("MulishBold"), url("./assets/fonts/Mulish-Bold.ttf") format("truetype");
}

/* @font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/fonts/Roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoItalic";
  src: local("RobotoItalic"), url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"), url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
} */





@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-LightItalic.eot');
  src: url('./assets/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-LightItalic.woff') format('woff'),
      url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-MediumItalic.eot');
  src: url('./assets/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-MediumItalic.woff') format('woff'),
      url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.eot');
  src: url('./assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Light.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Light.woff') format('woff'),
      url('./assets/fonts/Roboto-Light.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.eot');
  src: url('./assets/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Italic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Italic.woff') format('woff'),
      url('./assets/fonts/Roboto-Italic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.eot');
  src: url('./assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Medium.woff') format('woff'),
      url('./assets/fonts/Roboto-Medium.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.eot');
  src: url('./assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Regular.woff') format('woff'),
      url('./assets/fonts/Roboto-Regular.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.eot');
  src: url('./assets/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BoldItalic.woff') format('woff'),
      url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BlackItalic.eot');
  src: url('./assets/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BlackItalic.woff') format('woff'),
      url('./assets/fonts/Roboto-BlackItalic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.eot');
  src: url('./assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Bold.woff') format('woff'),
      url('./assets/fonts/Roboto-Bold.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-ThinItalic.eot');
  src: url('./assets/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-ThinItalic.woff') format('woff'),
      url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype'),
      url('./assets/fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Thin.eot');
  src: url('./assets/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Thin.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Thin.woff') format('woff'),
      url('./assets/fonts/Roboto-Thin.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.eot');
  src: url('./assets/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Roboto-Black.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Black.woff') format('woff'),
      url('./assets/fonts/Roboto-Black.ttf') format('truetype'),
      url('./assets/fonts/Roboto-Black.svg#Roboto-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: "HeadlandOne";
  src: local("HeadlandOne"), url("./assets/fonts/HeadlandOne.ttf") format("truetype");
}

@font-face {
  font-family: "Karla";
  src: local("Karla"), url("./assets/fonts/Karla.ttf") format("truetype");
}

@font-face {
  font-family: "KarlaBold";
  src: local("KarlaBold"), url("./assets/fonts/Karla-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "KarlaItalic";
  src: local("KarlaItalic"), url("./assets/fonts/Karla-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"), url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoItalic";
  src: local("LatoItalic"), url("./assets/fonts/Lato-Italic.ttf") format("truetype");
}


@font-face {
  font-family: 'New York';
  src: url('./assets/fonts/NewYorkMedium-Bold.woff2') format('woff2'),
      url('./assets/fonts/NewYorkMedium-Bold.woff') format('woff'),
      url('./assets/fonts/NewYorkMediumBold-2.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}








.MuiButtonBase-root.MuiButton-root {
  border: none !important;
}

.MuiButtonBase-root.MuiButton-root:hover {
  background: none !important;
}
.MuiButton-endIcon.MuiButton-iconSizeMedium.css-9tj150-MuiButton-endIcon {
  display: none !important;
}
